<template>
    <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 d-flex flex-column justify-content-center">
                    <h1 data-aos="fade-up">Looking Re:Start South Africa?</h1>
                    <h2 data-aos="fade-up" data-aos-delay="400">
                        We are looking for start-up business and talented
                        software developers
                    </h2>
                    <div data-aos="fade-up" data-aos-delay="600">
                        <div class="text-center">
                            <a
                                href="#about"
                                class="
                                    btn-get-started
                                    scrollto
                                    d-inline-flex
                                    align-items-center
                                    justify-content-center
                                    align-self-center
                                "
                            >
                                <span>Get Started</span>
                                <i class="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-6 hero-img"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                >
                    <img
                        src="../assets/img/features-2.png"
                        class="img-fluid"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </section>
    <!-- End Hero -->
</template>

<script>
export default {};
</script>

<style>
</style>