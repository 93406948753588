<template>
  <section id="about" class="about" style="background-color: #f2f5fc">

      <div class="container" data-aos="fade-up">
        <div class="row gx-0">

          <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div class="content">
              <h3>Why We Exist</h3>
              <h2>
                  We Exist to Empower, Equipment and Build the South African Economy on a Granular Level
              </h2>
              <p>
                  Re:Start ZA stands for "Re:" which means 'Reply' or 'Response' in this context this is our response
                  to the current situation we find ourselves in as South Africans. "Start ZA" refers to our start-up 
                  businesses in South Africa.
              </p>
              <div class="text-center">
                <!-- <a href="#" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Read More</span>
                  <i class="bi bi-arrow-right"></i>
                </a> -->
              </div>
            </div>
          </div>

          <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            <img src="../assets/img/about.jpg" class="img-fluid" alt="">
          </div>

        </div>
      </div>

    </section><!-- End About Section -->
</template>

<script>
export default {

}
</script>

<style>

</style>