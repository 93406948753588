import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import AOS from 'aos'
import 'aos/dist/aos.css'
// import "./assets/vendor/aos/aos.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/css/style.css";
// import "@/assets/vendor/bootstrap/js/bootstrap.bundle.js";
// import "@/assets/vendor/aos/aos.js";
import "@/assets/vendor/php-email-form/validate.js";
import "@/assets/vendor/swiper/swiper-bundle.min.js";
import "@/assets/vendor/purecounter/purecounter.js";
// import "@/assets/vendor/isotope-layout/isotope.pkgd.min.js";
import "@/assets/vendor/glightbox/js/glightbox.min.js";

const app = createApp(App)

app.AOS = new AOS.init();


app.mount('#app')