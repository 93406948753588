<template>
    <!-- ======= Header ======= -->
  <header id="header" class="header fixed-top py-3 ">
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

      <a href="index.html" class="logo d-flex align-items-center">
        <span class="pe-1">Re:Start   </span>
        <img src="../assets/img/South-Africa.png" alt="">
      </a>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a class="nav-link scrollto" href="#about">About</a></li>
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
          
          <li><button type="button" class="getstarted scrolltobtn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  Businesses 🏚
</button></li>
          <!-- Button trigger modal -->
          <li><button type="button" class="getstarted scrolltobtn btn-primary" data-bs-toggle="modal" data-bs-target="#devSignUp">
  Developers 💻
</button></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

    </div>
  </header>


<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title " id="staticBackdropLabel">Sign Up Business</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfW90if6vfQfa0aTu1ZO0q5bri5C26-r1JW5HI--ltCHgW7iw/viewform?embedded=true" width="450" height="1614" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary" @click.prevent="formSubmit">Understood</button> -->
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="devSignUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="devSignUpLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title " id="devSignUp">Sign Up Developers</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdsIkF8fj2gy-j2wCMgmEpo8zyz0TDDRVf4qqa8dWd7CQ9j5g/viewform?embedded=true" width="450" height="832" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary" @click.prevent="formSubmit">Understood</button> -->
      </div>
    </div>
  </div>
</div>
  
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#header {
  background-color: #fffcf5;
}
</style>
